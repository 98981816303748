import type { GatsbyBrowser } from 'gatsby';

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  if (location?.hash && location.hash !== '#cookie-preference') {
    const element = document?.querySelector(`${location.hash}`) as HTMLElement;

    if (element) {
      element.style.scrollBehavior = 'smooth';
      element.style.scrollSnapAlign = 'start';
      element.style.scrollSnapStop = 'normal';
      element.style.scrollMarginTop = '70px';
    }
  }
};

export { onRouteUpdate };
