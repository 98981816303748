module.exports = [{
      plugin: require('../../plugins/gatsby-plugin-browser-lucid/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../plugins/gatsby-source-marketo-lucid/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2HJB2Q","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lucid","short_name":"Lucid","description":"Empower the world to work visually at Lucid","start_url":"/","background_color":"#3D4752","theme_color":"#1071E5","display":"minimal-ui","icons":[{"src":"/logos/favicon.png","sizes":"32x32","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
