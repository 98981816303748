import getAppDomain from '../config/getAppDomain';
import { isDev } from '../env';
import isBrowser from '../isBrowser';

/** List of known cookies to sync with lucid.app
 *
 * @see https://git.lucidutil.com/projects/LUCID/repos/main/browse/cake/app/webroot/ts/cookiesync/app/cookieconfig.ts
 */
export const formatCookieDomain = (hostname: string, port = ''): string => {
  const appDomain = getAppDomain(hostname);
  const marketingDomainHost = 'lucidmarketingtest.com';
  const marketingDevDomain = `dev.${marketingDomainHost}`;
  const marketingStagingDomain = `staging.${marketingDomainHost}`;
  const marketingPreprodDomain = `preprod.${marketingDomainHost}`;

  if (hostname.endsWith(marketingDevDomain)) return hostname.replace(marketingDevDomain, appDomain);

  if (hostname.endsWith(marketingStagingDomain)) return hostname.replace(marketingStagingDomain, appDomain);

  if (hostname.endsWith(marketingPreprodDomain)) return hostname.replace(marketingPreprodDomain, appDomain);

  if (hostname === 'localhost' || (hostname === 'localhost' && port))
    return hostname.replace('localhost', `localhost${port ? `:${port}` : ''}`);

  return appDomain;
};

const setupCookieSync = (location?: Location | null) => {
  if (!location || !isBrowser()) return null;
  const { hostname, protocol, port } = location;
  const cookieSyncElement = document.getElementById('cookie-sync');

  if (!cookieSyncElement) {
    const iframeLocation = formatCookieDomain(hostname, port);
    const syncCookies = document.createElement('iframe');

    syncCookies.style.display = 'none';
    syncCookies.src = `${protocol}//${iframeLocation}/synchronizeCookies?responseDomain=${hostname}`;
    syncCookies.className = 'cookieSyncIframe';
    syncCookies.id = 'cookie-sync';
    syncCookies.dataset.osano = 'ESSENTIAL';

    if (!isDev()) {
      document.getElementsByTagName('body')[0].appendChild(syncCookies);
    }

    const syncAllCookies = () => {
      if (window && window.lucid && window.lucid?.cookieSyncClient) {
        window.lucid.cookieSyncClient.init(iframeLocation);
        window.lucid.cookieSyncClient.syncAllCookies();
        window.lucid.cookieSyncClient.syncAllMarketingCookies();
      }
    };

    syncCookies.onload = () => {
      console.info('FIRE COOKIE SYNC');
      syncAllCookies();
    };
  }
};

export default setupCookieSync;
