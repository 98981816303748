import JSCookies from 'js-cookie';

const getCookie = (name: string) => {
  const cookie = JSCookies.get(name) || undefined;

  return cookie;
};

export const setCookie = (name: string, value: string, options = {} as Cookies.CookieAttributes) => {
  const cookie = JSCookies.set(name, value, options);

  return cookie;
};

export const removeCookie = (name: string, options = {} as Cookies.CookieAttributes) => JSCookies.remove(name, options);

export default getCookie;
